<template>
  <div>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>Person als eingeteilt markieren</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-switch
          :disabled="disableInput"
          inset
          color="orange"
          append-icon="mdi-star"
        ></v-switch>
      </v-list-item-action>
    </v-list-item>

    <v-item-group>
      <v-container>
        <v-row>
          <v-col v-for="(n, index) in 6" :key="index" cols="12">
            <v-item v-slot="{ active, toggle }" :value="index">
              <v-card
                outlined
                @click="toggle"
                :style="active ? `border: 3px solid #1976d2 !important` : ''"
              >
                <v-card-title class="subtitle-1 font-weight-medium"
                  >Funktion <v-spacer></v-spacer
                  ><v-chip v-if="active" label small
                    ><v-icon left small
                      >mdi-checkbox-marked-circle-outline</v-icon
                    >Ausgewählt</v-chip
                  ></v-card-title
                >
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
export default {
  name: "response-position-editor",
  props: {
    responsePosition: {},
    mode: {
      type: String,
      default: "own",
      required: false,
      validator: function (value) {
        return ["own", "new", "edit"].indexOf(value) !== -1;
      },
    },

    disableInput: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
    };
  },
  model: {
    prop: "responsePosition",
    event: "responsePositionChange",
  },
  created() {},
  computed: {
    responsePositionLocal: {
      get: function () {
        return this.responsePosition;
      },
      set: function (value) {
        this.$emit("responsePositionChange", value);
      },
    },
  },
};
</script>

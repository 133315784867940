<template>
  <div>
    <v-item-group
      v-model="event.userResponse.status.id"
      mandatory
      class="mt-n1"
    >
      <v-container>
        <v-row justify="center">
          <v-item v-slot="{ active }" value="0">
            <v-col
              v-if="active"
              cols="12"
              lg="12"
              md="12"
              sm="12"
              style="padding: 6px !important"
              hidden
            >
              <v-card
                color="blue-grey lighten-5"
                class="d-flex align-center rounded-md"
                dark
                height="40"
                disabled
                flat
                :style="active ? `border: 3px solid #263238 !important` : ''"
              >
                <v-row>
                  <v-col cols="12" md="12" class="text-center mt-1">
                    <v-list-item-subtitle
                      class="font-weight-medium blue-grey--text text--darken-4"
                      >Aktuell: Keine Rückmeldung</v-list-item-subtitle
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-item>
          <v-col
            v-for="(item, index) in items"
            :key="index"
            cols="6"
            lg="3"
            md="3"
            sm="3"
            style="padding: 6px !important"
          >
            <v-item v-slot="{ active }" :value="item.value">
              <v-card
                :color="
                  $vuetify.theme.dark
                    ? `${item.color} lighten-4`
                    : `${item.color} lighten-5`
                "
                class="d-flex align-center rounded-lg"
                dark
                height="70"
                :disabled="disableItems"
                @click="updateResponse(item.value, index)"
                flat
                :style="getBorderStyle(active, item.borderColor)"
              >
                <v-row>
                  <v-col cols="12" md="12">
                    <v-list-item three-line class="text-center mt-1">
                      <v-list-item-content>
                        <v-progress-circular
                          v-if="item.loading"
                          indeterminate
                          :color="`${item.color} darken-4`"
                        ></v-progress-circular>
                        <div v-else>
                          <v-icon
                            :color="
                              $vuetify.theme.dark
                                ? `${item.color} darken-4`
                                : `${item.color} darken-4`
                            "
                            >{{ item.icon }}</v-icon
                          >
                        </div>
                        <v-list-item-subtitle
                          class="mt-n2 font-weight-medium"
                          :class="
                            $vuetify.theme.dark
                              ? `${item.color}--text text--darken-4`
                              : `${item.color}--text text--darken-4`
                          "
                          >{{ item.text }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
  </div>
</template>

<script>
import { auth } from "@/firebase";
import { SCHEDULING } from "@/store/modules.js";
import { UPDATE_EVENT_RESPONSE } from "@/store/action-types.js";
import { SCHEDULING_EVENTS_RESPONSES_OWN_update } from "@/data/permission-types.js";

export default {
  name: "event-response-quick-editor",
  props: {
    event: {},
  },
  data() {
    return {
      disableItems: false,
      items: [
        {
          icon: "mdi-check-circle",
          value: "1",
          loading: false,
          text: "Verfügbar",
          borderColor: { dark: "#1B5E20", light: "#1B5E20" },
          color: "green",
        },
        {
          icon: "mdi-minus-circle",
          value: "3",
          loading: false,
          text: "Wenn notwendig",
          borderColor: { dark: "#E65100", light: "#E65100" },
          color: "orange",
        },
        {
          icon: "mdi-close-circle",
          value: "2",
          loading: false,
          text: "Nicht verfügbar",
          borderColor: { dark: "#B71C1C", light: "#B71C1C" },
          color: "red",
        },
        {
          icon: "mdi-airplane",
          value: "4",
          loading: false,
          text: "Nicht in Stadt",
          borderColor: { dark: "#0D47A1", light: "#0D47A1" },
          color: "blue",
        },
      ],
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    checkIfItService() {
      return this.$store.getters["organization/checkIfItService"];
    },
    responseIsBinding() {
      var eventResponsesAreBinding = this.event.config.responses.areBinding;
      if (
        eventResponsesAreBinding &&
        this.event.userResponse.status.id !== ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    schedulingConfig() {
      return this.$store.state.organization.activeOrganization.config
        .scheduling;
    },
  },
  methods: {
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
    getBorderStyle(active, borderColor) {
      if (this.$vuetify.theme.dark) {
        if (active) {
          return `border: 3px solid ${borderColor.dark} !important`;
        } else {
          return ``;
        }
      } else {
        if (active) {
          return `border: 3px solid ${borderColor.light} !important`;
        } else {
          return ``;
        }
      }
    },
    userResponseIsNegative(responseStatusId) {
      var negativeValues = ["2", "3", "4"];
      return negativeValues.includes(responseStatusId);
    },
    deadlineHasExpired() {
      if (this.event) {
        var deadline = this.getDeadline(this.event.config.responses.deadline);
        var now = new Date();

        if (now.getTime() > deadline.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    getDeadline(deadline) {
      if (deadline.isCustom) {
        return deadline.timestamp.toDate();
      } else {
        // global deadline

        var durationInMin = this.schedulingConfig.defaultDeadlineInMinutes;
        //var durationInMin = 60;
        var MS_PER_MINUTE = 60000;
        var enddate = this.event.duration.start.firebaseFormat.toDate();
        var calc = new Date(enddate - durationInMin * MS_PER_MINUTE);

        // V2: also working
        // var startdate = new Date(enddate);
        // const output = new Date(startdate.setUTCMinutes(enddate.getUTCMinutes() - durationInMin));

        return calc;
      }
    },
    userIsInvited(event) {
      if (event.config.attendance.isLimited) {
        const userGroupId = this.$store.state.organization.membership.group.id;
        if (
          event.config.attendance.allowedAttendeesIds.includes("all") ||
          event.config.attendance.allowedAttendeesIds.includes(
            auth.currentUser.uid
          ) ||
          event.config.attendance.allowedAttendeesIds.includes(userGroupId)
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    updateResponse(newStatus, index) {
      if (this.checkIfSupport || this.checkIfItService) {
        return alert(
          "Fehler: Du verwendest ein IT-Service- oder Support-Konto und kannst keine eigenen Rückmeldungen abgeben."
        );
      } else if (
        !this.checkPermission(`${SCHEDULING_EVENTS_RESPONSES_OWN_update}`)
      ) {
        return alert(
          "Fehler: Du bist nicht berechtigt, diese Aktion auszuführen."
        );
      } else if (
        this.event.config.attendance.isLimited &&
        !this.userIsInvited(this.event)
      ) {
        return alert(
          "Fehler: Die Teilnahme an dieser Veranstaltung ist beschränkt."
        );
      } else if (this.deadlineHasExpired()) {
        return alert(
          "Fehler: Die Rückmeldefrist ist bereits abgelaufen. Eine Rückmeldung kann nicht mehr abgegeben werden."
        );
      } else if (
        this.responseIsBinding &&
        !this.event.userResponse.status.id !== "0"
      ) {
        const res = confirm(
          "Abgegebene Rückmeldungen sind verbindlich. Eine abgegebene Rückmeldung kann anschließend nicht mehr geändert werden.\n\nRückmeldung speichern?"
        );
        if (res == true) {
          return this.updateEventResponse(newStatus, index);
        }
      } else if (
        this.event.config.attendance.isMandatory &&
        this.userResponseIsNegative(newStatus)
      ) {
        const res = confirm(
          "Für diese Veranstaltung besteht Anwesenheitspflicht!\nBist Du sicher, dass Du deine Rückmeldung absenden möchtest?"
        );
        if (res == true) {
          return this.updateEventResponse(newStatus, index);
        }
      } else if (
        this.event.userResponse.isAccepted &&
        (newStatus === "2" || newStatus === "4")
      ) {
        const res = confirm(
          "Du bist für diese Veranstaltung bereits eingeteilt!\nBist Du sicher, dass Du deine Rückmeldung absenden möchtest? Deine Einteilung wird damit aufgehoben."
        );
        if (res == true) {
          return this.updateEventResponse(newStatus, index);
        }
      } else {
        return this.updateEventResponse(newStatus, index);
      }
    },
    updateEventResponse(newStatus, index) {
      this.disableItems = true;
      var oldText = this.items[index].text;
      this.items[index].loading = true;
      this.items[index].text = "Aktualisieren...";
      const response = this.event.userResponse;
      const hasConflictingStatus = newStatus === "2" || newStatus === "4";
      return this.$store
        .dispatch(`${SCHEDULING}${UPDATE_EVENT_RESPONSE}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.event.meta.id,

          uid: auth.currentUser.uid,
          status: {
            id: newStatus,
          },
          assignedTasks: response.assignedTasks,
          isAccepted:
            response.isAccepted && hasConflictingStatus
              ? false
              : response.isAccepted,
          isCanceled: false,
          assignedPosition: {
            custom: {
              text:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.custom.text,
            },
            unit: {
              id:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.unit.id,
              title:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.unit.title,
            },
            position: {
              id:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.position.id,
              shortTitle:
                response.isAccepted && hasConflictingStatus
                  ? ""
                  : response.assignedPosition.position.shortTitle,
            },
            comboId:
              response.isAccepted && hasConflictingStatus
                ? ""
                : response.assignedPosition.comboId,
          },
          guests: {
            count: response.guests.count,
          },
          comment: response.comment,
        })
        .then(() => {
          setTimeout(() => {
            this.items[index].text = oldText;
            response.status.id = newStatus;
            if (response.isAccepted && hasConflictingStatus) {
              response.isAccepted = false;
              response.assignedPosition.custom.text = "";
              response.assignedPosition.unit.id = "";
              response.assignedPosition.unit.title = "";
              response.assignedPosition.position.id = "";
              response.assignedPosition.position.shortTitle = "";
              response.assignedPosition.comboId = "";
            }
            this.items[index].loading = false;
            this.disableItems = false;
          }, 1000);
        })
        .catch((error) => {
          this.disableItems = false;
          this.items[index].loading = false;
          console.log(error);
        });
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <template>
      <v-select
        v-if="mode === 'edit'"
        v-model="responseLocal.user"
        label="Person"
        item-text="displayName"
        item-value="uid"
        disable-lookup
        :disabled="mode !== 'edit' || disablePersonSelect"
        single-line
        outlined
      ></v-select>
      <v-select
        v-model="responseLocal.status.id"
        :items="items"
        item-text="status"
        label="Rückmeldung auswählen"
        hide-details="auto"
        :disabled="disableInput"
        outlined
        :rules="[rules.required]"
        class="mb-4"
      >
        <template v-slot:item="data">
          <v-list-item>
            <v-list-item-action>
              <v-icon :color="data.item.color">
                {{ data.item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.status }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="data">
          <v-list-item>
            <v-list-item-action>
              <v-icon :color="data.item.color">
                {{ data.item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ data.item.status }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
      <v-textarea
        v-model.trim="responseLocal.comment"
        clearable
        no-resize
        label="Kommentar"
        :disabled="disableInput"
        hide-details="auto"
        outlined
        rows="3"
        class="mb-4"
      ></v-textarea>
      <v-text-field
        v-if="guestsActivated"
        v-model.number="responseLocal.guests.count"
        type="number"
        outlined
        :disabled="disableInput"
        hide-details="auto"
        label="Anzahl Gäste"
        class="mb-4"
      ></v-text-field>
      <v-select
        v-if="tasksActivated"
        v-model="responseLocal.assignedTasks"
        :items="tasks"
        outlined
        :disabled="disableInput"
        multiple
        clearable
        small-chips
        item-text="title"
        item-key="id"
        return-object
        hide-details="auto"
        label="Aufgaben übernehmen"
        no-data-text="Keine Aufgaben für diese Veranstaltung verfügbar"
        class="mb-4"
      ></v-select>
      <v-btn
        v-if="!guestsActivated && !disableInput"
        depressed
        small
        @click="guestsActivated = !guestsActivated"
        class="mr-2"
      >
        <v-icon left>mdi-account-multiple-plus</v-icon>
        Gäste hinzufügen
      </v-btn>
      <v-btn
        v-if="!tasksActivated && !disableInput"
        depressed
        small
        class="mr-2"
        @click="tasksActivated = !tasksActivated"
      >
        <v-icon left>mdi-checkbox-marked-circle-plus-outline</v-icon>
        Aufgabe übernehmen
      </v-btn>
    </template>
  </div>
</template>

<script>
export default {
  name: "response-editor",
  props: {
    response: {},
    mode: {
      type: String,
      default: "own",
      required: false,
      validator: function (value) {
        return ["own", "new", "edit"].indexOf(value) !== -1;
      },
    },
    memberships: {
      type: Array,
      required: false,
    },
    disableInput: {
      type: Boolean,
      required: false,
      default: false,
    },
    disablePersonSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    tasks: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      guestsActivated: false,
      tasksActivated: false,
      items: [
        {
          icon: "mdi-check",
          value: "1",
          status: "Verfügbar",
          color: "success",
        },
        {
          icon: "mdi-close",
          value: "2",
          status: "Nicht verfügbar",
          color: "error",
        },
        {
          icon: "mdi-minus",
          value: "3",
          status: "Wenn notwendig",
          color: "warning",
        },
        {
          icon: "mdi-airplane",
          value: "4",
          status: "Nicht in Stadt",
          color: "info",
        },
      ],
    };
  },
  model: {
    prop: "response",
    event: "responseChange",
  },
  created() {
    if (this.responseLocal.guests.count > 0) {
      this.guestsActivated = true;
    }
    if (this.responseLocal.assignedTasks.length > 0) {
      this.tasksActivated = true;
    }
    if (this.mode === "edit") {
      this.items.splice(0, 0, {
        value: "0",
        icon: "mdi-help",
        disabled: true,
        status: "Keine Rückmeldung",
        color: "blue-grey darken-2",
      });
    }
  },
  computed: {
    responseLocal: {
      get: function () {
        return this.response;
      },
      set: function (value) {
        this.$emit("responseChange", value);
      },
    },
  },
};
</script>

<template>
  <div>
    <v-data-table
      :headers="computedHeaders"
      hide-default-footer
      disable-pagination
      disable-filtering
      :items="eventResponses"
      item-key="meta.uid"
      :sort-by.sync="sortBy"
      :group-by.sync="groupBy"
      :group-desc.sync="groupDesc"
      :sort-desc.sync="sortDesc"
      dense
      mobile-breakpoint="0"
      no-data-text="Keine Rückmeldungen für diese Veranstaltung"
    >
      <template v-slot:top>
        <v-card flat>
          <v-card-title class="v-card__subtitle">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on" class="mr-2">
                  <span>
                    Gruppieren nach
                    <strong>{{ btnToggleText }}</strong>
                  </span>
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list nav>
                <v-list-item-group v-model="btnToggle" mandatory>
                  <v-list-item link dense value="status">
                    <v-list-item-icon>
                      <v-icon dense>mdi-list-status</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Status</v-list-item-title>
                  </v-list-item>
                  <v-list-item link dense value="group">
                    <v-list-item-icon>
                      <v-icon dense>mdi-folder-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Benutzergruppe</v-list-item-title>
                  </v-list-item>
                  <!-- <v-list-item link dense disabled value="skills">
                    <v-list-item-icon>
                      <v-icon dense>mdi-tag</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Qualifikationen</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item link dense value="division">
                    <v-list-item-icon>
                      <v-icon dense>mdi-home-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Einheit/Abteilung</v-list-item-title>
                  </v-list-item>
                  <v-list-item link dense disabled value="performanceTarget">
                    <v-list-item-icon>
                      <v-icon dense disabled>mdi-bullseye-arrow</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Zielvorgaben</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-btn
              v-if="
                mode === 'view' &&
                checkPermission(manageResponsesPermissionId) &&
                eventHasNotStarted &&
                deadlineNotExpired
              "
              color="primary"
              depressed
              :to="{
                name: 'scheduling-event-details-manage-responses',
                params: {
                  organizationId: $route.params.organizationId,
                  itemId: $route.params.itemId,
                },
              }"
              >Bearbeiten</v-btn
            >
            <!-- <v-btn
              v-else-if="
                mode === 'manage' &&
                checkPermission(manageResponsesPermissionId) &&
                eventHasNotStarted
              "
              color="primary"
              depressed
              :disabled="selectedRows.length === 0"
              @click="acceptResponsesOfSelection(selectedRows)"
              ><v-icon left>mdi-account-star</v-icon>Ausgewählte Personen
              einteilen</v-btn
            > -->
          </v-card-title>
        </v-card>
      </template>
      <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
        <th
          :colspan="
            mode === 'manage'
              ? computedHeaders.length++
              : computedHeaders.length
          "
        >
          <v-icon @click="toggle" class="mr-2"
            >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
          <span v-if="btnToggle === 'status'">
            {{ stripPrefix(items[0].groupByStatus) }}
          </span>
          <span v-else-if="btnToggle === 'group'">
            {{ stripPrefix(items[0].groupByGroup) }}
          </span>
          <span v-else-if="btnToggle === 'division'">
            {{ stripPrefix(items[0].groupByDivision) }}
          </span>
          <span v-else>
            {{ stripPrefix(items[0].groupByAssignment) }}
          </span>
        </th>
      </template>
      <!-- <template v-slot:item="{ item, isSelected, select }"> -->
      <template v-slot:item="{ item }">
        <tr class="parent-tr">
          <!-- <td v-if="mode === 'manage'"> -->
          <!-- <v-simple-checkbox
              :ripple="false"
              :value="isSelected"
              :disabled="item.status.id === '2' || item.status.id === '4'"
              @input="select($event)"
            ></v-simple-checkbox> -->
          <!-- <v-btn
              small
              icon
              @click="acceptResponse(item)"
              :color="item.isAccepted ? 'warning' : ''"
              :disabled="
                !item.isAccepted &&
                (item.status.id === '2' || item.status.id === '4')
              "
              ><v-icon>
                {{
                  item.isAccepted
                    ? "mdi-star"
                    : item.status.id === "2" || item.status.id === "4"
                    ? "mdi-star-off"
                    : "mdi-star-outline"
                }}</v-icon
              ></v-btn
            >
          </td> -->
          <td class="inner-td">
            <div :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : ''">
              <v-badge
                bordered
                overlap
                bottom
                :color="statusColor(item.status.id)"
                :icon="statusIcon(item.status.id)"
                class="mr-6"
                :class="$vuetify.breakpoint.smAndDown ? 'mb-2' : ''"
              >
                <template v-slot:badge
                  ><v-icon small class="mb-0" dark>
                    {{ statusIcon(item.status.id) }}
                  </v-icon></template
                >
                <v-avatar
                  rounded
                  size="38"
                  :color="
                    $vuetify.theme.dark
                      ? 'rgba(255, 255, 255, 0.12)'
                      : 'grey lighten-2'
                  "
                  class="font-weight-medium"
                  >{{ initials(item.user.displayName) }}</v-avatar
                >
              </v-badge>
              <span style="text-align: start"
                >{{ item.user.displayName }}
              </span>
            </div>
            <div v-if="item.comment" class="hidden-md-and-up mt-3 mb-2 ml-4">
              <v-icon small class="mr-3">mdi-comment-text</v-icon
              >{{ item.comment }}
            </div>
            <div
              v-if="item.guests.count !== 0"
              class="hidden-md-and-up mt-3 mb-2 ml-4"
            >
              <v-icon small class="mr-3">mdi-account-group</v-icon>+
              {{ item.guests.count }}
              {{ guestText(item.guests.count) }}
            </div>
            <div
              v-if="item.assignedTasks.length > 0"
              class="hidden-md-and-up mt-3 mb-2 ml-4"
            >
              <v-icon small class="mr-3">mdi-clipboard-list</v-icon>
              <v-chip
                v-for="(task, index) in item.assignedTasks"
                :key="index"
                small
                label
                outlined
                class="font-weight-medium mr-1"
                ><v-icon v-if="task.icon" small left>mdi-{{ task.icon }}</v-icon
                >{{ task.title }}</v-chip
              >
            </div>
            <div
              v-if="item.meta.lastUpdatedByDisplayName"
              class="hidden-md-and-up mt-3 mb-2 ml-4"
            >
              <v-icon small class="mr-3">mdi-message-alert</v-icon>
              Bearbeitet von
              <strong>
                {{ item.meta.lastUpdatedByDisplayName }}
              </strong>
              am
              {{ timestampConverter(item.meta.lastUpdatedAt) }}
              Uhr
            </div>
            <div
              class="hidden-md-and-up mt-3 mb-2 ml-4"
              v-if="
                item.isAccepted ||
                item.isCanceled ||
                item.assignedPosition.custom.text ||
                item.assignedPosition.comboId
              "
            >
              <v-chip
                v-if="item.isAccepted"
                label
                small
                class="font-weight-medium mr-2 orange--text text--darken-4"
                color="orange lighten-4"
                ><v-icon left small>mdi-star</v-icon>Eingeteilt</v-chip
              >
              <v-chip
                v-if="item.isCanceled"
                color="red lighten-4"
                class="font-weight-medium red--text text--darken-4"
                label
                small
                >Kurzfristige Abmeldung</v-chip
              >
              <v-chip
                v-if="item.assignedPosition.comboId"
                label
                small
                class="font-weight-medium mr-2"
                ><v-icon left small>mdi-fire-truck</v-icon
                >{{ item.assignedPosition.unit.title }} –
                {{ item.assignedPosition.position.shortTitle }}</v-chip
              >
              <v-chip
                v-if="item.assignedPosition.custom.text"
                label
                small
                class="font-weight-medium"
              >
                {{ item.assignedPosition.custom.text }}
              </v-chip>
            </div>
            <div
              v-if="
                item.type === 'automatedFromLeave' ||
                item.type === 'copiedFromRequest'
              "
              class="hidden-md-and-up mt-3 mb-2 ml-4"
            >
              <v-chip
                color="light-blue lighten-4"
                label
                small
                class="font-weight-medium light-blue--text text--darken-4"
                >{{
                  item.type === "automatedFromLeave"
                    ? "Automatisierte Rückmeldung"
                    : "Übernommen aus Abfrage"
                }}</v-chip
              >
            </div>
          </td>
          <td v-if="!$vuetify.breakpoint.smAndDown">
            <div v-if="item.comment" class="my-3">
              <v-icon small class="mr-3">mdi-comment-text</v-icon
              >{{ item.comment }}
            </div>
            <div v-if="item.guests.count !== 0">
              <v-icon small class="mr-3">mdi-account-group</v-icon>+
              {{ item.guests.count }}
              {{ guestText(item.guests.count) }}
            </div>
            <div v-if="item.assignedTasks.length > 0" class="my-3">
              <v-icon small class="mr-3">mdi-clipboard-list</v-icon>
              <v-chip
                v-for="(task, index) in item.assignedTasks"
                :key="index"
                small
                label
                outlined
                class="font-weight-medium mr-1"
                ><v-icon v-if="task.icon" small left>mdi-{{ task.icon }}</v-icon
                >{{ task.title }}</v-chip
              >
            </div>
            <div v-if="item.meta.lastUpdatedByDisplayName" class="my-3">
              <v-icon small class="mr-3">mdi-message-alert</v-icon>
              Bearbeitet von
              <strong>
                {{ item.meta.lastUpdatedByDisplayName }}
              </strong>
              <span v-if="timestampConverter(item.meta.lastUpdatedAt)">
                am
                {{ timestampConverter(item.meta.lastUpdatedAt) }}
                Uhr</span
              >
            </div>
            <div
              v-if="
                item.type === 'automatedFromLeave' ||
                item.type === 'copiedFromRequest'
              "
              class="my-3"
            >
              <v-chip
                color="light-blue lighten-4"
                label
                small
                class="font-weight-medium light-blue--text text--darken-4"
                >{{
                  item.type === "automatedFromLeave"
                    ? "Automatisierte Rückmeldung"
                    : "Übernommen aus Abfrage"
                }}</v-chip
              >
            </div>
            <div
              class="my-3"
              v-if="
                item.isAccepted ||
                item.isCanceled ||
                item.assignedPosition.custom.text ||
                item.assignedPosition.comboId
              "
            >
              <v-chip
                v-if="item.isAccepted"
                label
                small
                class="font-weight-medium mr-2 orange--text text--darken-4"
                color="orange lighten-4"
                ><v-icon left small>mdi-star</v-icon>Eingeteilt</v-chip
              >
              <v-chip
                v-if="item.isCanceled"
                color="red lighten-4"
                class="font-weight-medium red--text text--darken-4"
                label
                small
                >Kurzfristige Abmeldung</v-chip
              >
              <v-chip
                v-if="item.assignedPosition.comboId"
                label
                small
                class="font-weight-medium mr-2"
                ><v-icon left small>mdi-fire-truck</v-icon
                >{{ item.assignedPosition.unit.title }} –
                {{ item.assignedPosition.position.shortTitle }}</v-chip
              >
              <v-chip
                v-if="item.assignedPosition.custom.text"
                label
                small
                class="font-weight-medium"
              >
                {{ item.assignedPosition.custom.text }}
              </v-chip>
            </div>
          </td>
          <td v-if="mode === 'manage' && !$vuetify.breakpoint.xsAndDown">
            <v-btn
              icon
              @click="openResponseEditor(item)"
              :disabled="item.isCanceled"
            >
              <v-icon dense> mdi-pencil </v-icon>
            </v-btn>
            <v-btn
              icon
              class="mr-2"
              @click="openPositionEditor(item)"
              :disabled="true || item.isCanceled"
            >
              <v-icon dense> mdi-fire-truck </v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase.js";
import { SCHEDULING_EVENTS_RESPONSES_write } from "@/data/permission-types.js";
export default {
  name: "event-responses-data-table",
  props: {
    eventResponses: {
      required: true,
    },
    mode: {
      type: String,
      default: "view",
      required: false,
      validator: function (value) {
        return ["view", "manage"].indexOf(value) !== -1;
      },
    },
  },
  components: {},
  data() {
    return {
      manageResponsesPermissionId: `${SCHEDULING_EVENTS_RESPONSES_write}`,
      selectedRows: [],
      baseHeaders: [
        { text: "Name und Status", value: "user.displayName" },
        {
          text: "Kommentar etc.",
          value: "comment",
          hide: "smAndDown",
          divider: true,
          sortable: false,
        },
      ],
      groupDesc: false,
      sortDesc: false,
      btnToggle: "status",
      items: [
        {
          icon: "mdi-check",
          value: "1",
          status: "Verfügbar",
          color: "success",
        },
        {
          icon: "mdi-close",
          value: "2",
          status: "Nicht verfügbar",
          color: "error",
        },
        {
          icon: "mdi-minus",
          value: "3",
          status: "Wenn notwendig",
          color: "warning",
        },
        {
          icon: "mdi-airplane",
          value: "4",
          status: "Nicht in Stadt",
          color: "info",
        },
      ],
    };
  },
  //   model: {
  //     prop: "response",
  //     event: "responseChange",
  //   },
  computed: {
    computedHeaders() {
      const headers = this.baseHeaders || [];
      return headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    deadlineNotExpired() {
      return true;
    },
    eventHasNotStarted() {
      return true;
    },
    sortBy() {
      if (this.btnToggle == 0) {
        return "user.displayName";
      } else {
        return "user.displayName";
      }
    },
    btnToggleText() {
      if (this.btnToggle === "status") {
        return "Status";
      } else if (this.btnToggle === "group") {
        return "Benutzergruppe";
      } else if (this.btnToggle === "division") {
        return "Einheit/Abteilung";
      } else if (this.btnToggle === "skills") {
        return "Qualifikationen";
      } else if (this.btnToggle === "performanceTarget") {
        return "Zielvorgaben";
      } else {
        return "Benutzergruppe";
      }
    },
    groupBy() {
      if (this.btnToggle === "status") {
        return "groupByStatus";
      } else if (this.btnToggle === "group") {
        return "groupByGroup";
      } else if (this.btnToggle === "division") {
        return "groupByDivision";
      } else if (this.btnToggle === "skills") {
        return "groupBySkills";
      } else if (this.btnToggle === "performanceTarget") {
        return "groupByPerformanceTarget";
      } else {
        return "groupByGroup";
      }
    },
    // responseLocal: {
    //   get: function () {
    //     return this.survey;
    //   },
    //   set: function (value) {
    //     this.$emit("responseChange", value);
    //   },
    // },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.mode === "manage") {
        this.baseHeaders.push({
          text: "Aktionen",
          value: "actions",
          align: "right",
          width: "1%",
          sortable: false,
        });
      }
    },
    openResponseEditor(response) {
      this.$emit("open-response-editor", response);
    },
    openPositionEditor(response) {
      this.$emit("open-position-editor", response);
    },
    acceptResponse(response) {
      this.$emit("accept-response", response);
    },
    // acceptResponsesOfSelection(selectedUsers) {
    //   this.$emit("accept-responses-of-selection", selectedUsers);
    //   this.selectedRows = [];
    // },
    guestText(item) {
      if (item > 1) {
        return "Gäste";
      }
      return "Gast";
    },
    initials(fullName) {
      const namesArray = fullName.trim().split(" ");
      if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
      return `${namesArray[0].charAt(0)}${namesArray[
        namesArray.length - 1
      ].charAt(0)}`;
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    statusIcon(item) {
      switch (item) {
        case "-1":
          return "mdi-cancel";
        case "0":
          return "mdi-help";
        case "1":
          return "mdi-check";
        case "2":
          return "mdi-close";
        case "3":
          return "mdi-minus";
        case "4":
          return "mdi-airplane";
        case "":
          return "mdi-alert-outline";
        default:
          return "";
      }
    },
    statusColor(item) {
      switch (item) {
        case "-1":
          return "purple darken-2";
        case "0":
          return "blue-grey darken-2";
        case "1":
          return "success";
        case "2":
          return "error";
        case "3":
          return "warning";
        case "4":
          return "info";
        case "":
          return "blue-grey darken-2";
        default:
          return "";
      }
    },
    timestampConverter(timestamp) {
      if (!(timestamp instanceof Timestamp)) {
        return "";
      }

      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
    checkPermission(item) {
      if (item == "" || item == undefined) {
        return true;
      } else {
        return this.$store.getters["organization/checkPermissionByID"](item);
      }
    },
  },
};
</script>

<style>
.parent-tr {
  height: 80px;
}
</style>
